import { GlobalCacheProvider } from '@/lib/emotion'
import { generateBeyondImageLoader } from '@/lib/utils'
import { CloseIcon } from '@chakra-ui/icons'
import {
  Box,
  Center,
  Flex,
  Heading,
  IconButton,
  Spacer,
  useBreakpointValue,
  useTheme
} from '@chakra-ui/react'
import Image from 'next/future/image'
import React from 'react'
import ReactDOM from 'react-dom'
import { Carousel } from '../core/Carousel'

const DisplayImage = ({
  i = 0,
  selectedImage,
  total,
  image,
  beyondImageLoader,
  displaySize
}) => {
  // Load only next and previous image
  const distance = Math.min(
    Math.abs(selectedImage - i),
    total - Math.abs(selectedImage - i)
  )

  return (
    <Flex bg="transparent" p="4" width="100%" height="100%" alignItems="center">
      <Image
        loader={beyondImageLoader}
        width={displaySize.width}
        height={displaySize.height}
        alt={image.alt_text}
        src={image.full_url}
        priority={distance <= 1}
        style={{
          maxHeight:
            image.image_description || image.title_text
              ? 'calc(100vh - 20vh)'
              : 'calc(100vh - 32px)',
          objectFit: 'contain',
          borderRadius: '4px'
        }}
      />
    </Flex>
  )
}

export function PopupImageGallery({
  selectedImage,
  onChange,
  property,
  settings,
  onClose
}) {
  const maxImages = settings.maxImages ?? 100
  const isMobile = useBreakpointValue({ base: true, md: false })
  const imageSize = isMobile ? 'medium' : 'large'
  const displaySize = settings.uploadImageSize[imageSize]
  const beyondImageLoader = generateBeyondImageLoader(settings, imageSize)
  const theme = useTheme()

  const images = [...property.images]
  images.length = images.length > maxImages ? maxImages : images.length

  // Disable scroll on body while we're in the popup gallery
  React.useEffect(() => {
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = 'initial'
    }
  }, [])

  return ReactDOM.createPortal(
    <GlobalCacheProvider>
      <Box
        w="100vw"
        h="100vh"
        position="fixed"
        bg="gray.700"
        zIndex="9999"
        top="0"
        left="0"
      >
        <Box w="100%" position="absolute">
          <IconButton
            aria-label="Close gallery"
            icon={<CloseIcon />}
            top="2"
            right="2"
            position="absolute !important"
            onClick={onClose}
            zIndex="3"
            variant="unstyled"
            color="gray.200"
            rounded="full"
            border="none"
            cursor="pointer"
            sx={{
              // Extra specific to avoid problems with client sites' CSS
              '&:active, &:focus': {
                bg: 'none !important',
                color: '#000 !important',
                border: 'none !important',
                outline: 'none !important',
                position: 'absolute !important',
                top: `${theme.space[2]} !important`,
                right: `${theme.space[2]} !important`
              }
            }}
          />
          <Center
            textAlign="center"
            position="absolute"
            width="100%"
            h="14"
            zIndex="2"
          >
            <Heading
              size="md"
              bgColor="gray.700"
              py="1"
              px="2"
              rounded="lg"
              color="white"
              opacity="80%"
            >
              {selectedImage + 1}/{images.length}
            </Heading>
          </Center>
        </Box>
        <Flex direction="column" height="100%">
          <Spacer />
          <Carousel selectedItem={selectedImage} onChange={onChange}>
            {images.map((image, i) => (
              <Flex direction="column" key={image.full_url}>
                <DisplayImage
                  i={i}
                  key={i}
                  image={image}
                  total={images.length}
                  selectedImage={selectedImage}
                  beyondImageLoader={beyondImageLoader}
                  displaySize={displaySize}
                />
                <Box w="100%">
                  <Center textAlign="center" width="100%" h="14" zIndex="2">
                    <Heading
                      size="md"
                      bgColor="gray.700"
                      py="1"
                      px="2"
                      rounded="lg"
                      color="white"
                      opacity="80%"
                    >
                      {image.image_description || image.title_text}
                    </Heading>
                  </Center>
                </Box>
              </Flex>
            ))}
          </Carousel>
          <Spacer />
        </Flex>
      </Box>
    </GlobalCacheProvider>,
    document.body
  )
}
